import React, { useState, useEffect } from "react";
import styles from './OrdersNotes.module.scss';
import Notes from "../../../common/Utils/Notes/Notes"; 
import { OrdersComponentApi, UserProfileApi } from "../../../constants/ApiConstants";
import { useLocation } from "react-router-dom";
import { HTTPMethod, callApi as ordersNoteRequest } from "../../../services/HttpService/HttpService";
import { formatCanceledDateTime, handleAddNote } from "../../../common/Utils/Notes/NotesCommonMethods";

const OrdersNotes = () => {
  const [noteContent, setNoteContent] = useState("");
  const [ordersNoteData, setOrdersNoteData] = useState([]);
  const location = useLocation();
  const [refreshOrders, setRefreshOrders] = useState(false);
  const [isLoadingForOrders, setLoadingForOrders] = useState(true);
  const [imageArray, setImageArray] = useState([]);
  const noNoteExist = `No notes exist for this order.`;


  useEffect(() => {
    if (ordersNoteData?.order_notes?.length > 0) {
      let updatedNotes = ordersNoteData;  
      updatedNotes?.order_notes.forEach((item, index) => {
        const matchingImage = imageArray.find(value => value.imagejpg === item.profile_image);  
        if (matchingImage) {
          updatedNotes[index] = {
            ...item,
            profile_image_url: matchingImage.image
          };
        }
      });
      setOrdersNoteData(updatedNotes);
      
    }
  }, [imageArray, ordersNoteData]);

    const fetchData = async () => {
      let image = [];
      setLoadingForOrders(true);
      setOrdersNoteData([]);
      const requestBody = location.pathname.split('/').pop();
      const apiEndpoint = location.pathname.includes("supply-order-details")
      ? OrdersComponentApi.supplyOrderNotes.replace("{order_id}", requestBody)
      : OrdersComponentApi.ordersNotes.replace("{order_id}", requestBody);
    
        const response = await ordersNoteRequest(HTTPMethod.Get, apiEndpoint);
        if (response && response.status_code == 200 && response.data) {
          if(response.data.order_notes.length > 0)
          {
            response.data.order_notes.map(async(item, index) => {
              const requestBody = {
                image_url: item.profile_image
              }
              const profileResponse = await ordersNoteRequest(
                HTTPMethod.Post,
                UserProfileApi.userProfileImage,
                requestBody
              );
              if (profileResponse && profileResponse.status_code == 200){
              
                image.push({
                image:  profileResponse.data.image, 
                imagejpg: item.profile_image
                });

              }
              if(response.data.order_notes.length-1 == index){
                setImageArray(image);
              }
            })
        
          } 
          setOrdersNoteData(response.data);
          setLoadingForOrders(false);
        }else{
          setLoadingForOrders(false);
        }
    };
   
    useEffect(() => {
   
      fetchData();
  
      if (refreshOrders) {
        setRefreshOrders(false);
      }
    }, [refreshOrders]);

  const handleNoteChange = (e) => {
    setNoteContent(e.target.value);
  };

  return (
    <div className={styles.OrdersNote} data-testid="OrdersNote">
      <div className={styles.customDiv}>
        <Notes
          uniqueKey='ordersNotes'
          noteContent={noteContent}
          setNoteContent={setNoteContent}
          notesData={ordersNoteData.order_notes}
          isLoading={isLoadingForOrders}
          noNoteExistMessage={noNoteExist}
          onNoteChange={handleNoteChange}
          onAddNote={()=>handleAddNote(location, noteContent, setNoteContent, setRefreshOrders)}
          formatDateTime={formatCanceledDateTime}
          classes={styles}
          imageArray={imageArray}
        />
      </div>
    </div>
  );
};

export default OrdersNotes;
