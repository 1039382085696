import { createSlice } from '@reduxjs/toolkit';
import { setSentryUser } from '../../common/Utils/Sentry/Sentry';

const initialState = {
  isLoggedIn: false,
  isExpired:false,
  isSessionInactive:false,
  isTokenExpired: false,
  isAccessToken: false,
  userData: {

  },
  notificationCount: {
    totalCount: 0,
    filteredCount: 0
  }
}
const AuthSlice = createSlice({
  name: 'authReducer',
  initialState,
  reducers: {
    LoginSuccess(state, action) {
      if (action.payload.access_token) {
        localStorage.setItem('accessToken', action.payload.access_token);
        if (action.payload.refresh_token)
          localStorage.setItem('refreshToken', action.payload.refresh_token);
      }
      return {
        ...state,
        isExpired:false,
        isSessionInactive:false,
        isTokenExpired: false,
      }
    },
    LoginInitial(state) {
      return {
        ...state,
        isLoggedIn: true,
        isExpired: false,
        isSessionInactive:false,
        isTokenExpired: false,
      };
    },
    UserConfiguration(state, action) {
      window.isTokenApiCalled = false;
      localStorage.setItem('user_data', JSON.stringify(action.payload.data.user_info));
      localStorage.setItem('impersonation_data', JSON.stringify(action.payload.data.impersonation));
      setSentryUser(action.payload.data.user_info.user_id)
      return {
        ...state,
        isTokenExpired: false,
        userData: action.payload
      }
    },
    LogoutSuccess(state) {
      localStorage.clear();
      sessionStorage.removeItem('relaunchSteps')
      return {
        ...state,
        isLoggedIn: false,
        isTokenExpired: false,
        isSessionInactive: false,
      };
    },
    ExpiredSession(state) {
      localStorage.clear();
      return{
        ...state,
        isExpired:true,
      };
    },
     SessionInactive(state) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('LoginResponse');
      sessionStorage.removeItem('relaunchSteps')
      return {
        ...state,
        isSessionInactive: true,
      };
    },
    TokenExpired(state) {
      return {
        ...state,
        isTokenExpired: true,
      };
    },
    GetAccessToken(state, action) {
      return {
        ...state,
        isAccessToken: action.payload.isAccessToken,
      };
    },
    GetNotificationCount(state, action) {
      return {
        ...state,
        notificationCount: action.payload.notificationCount,
      };
    }
  }
})

export const { GetNotificationCount, LoginSuccess, LoginInitial, LogoutSuccess, UserConfiguration, ExpiredSession, SessionInactive, TokenExpired, GetAccessToken } = AuthSlice.actions;


export default AuthSlice.reducer;
