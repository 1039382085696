import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import InventoryIcon from "@mui/icons-material/Inventory";
import { faClipboardCheck } from "@fortawesome/pro-solid-svg-icons";
import { Card, CardContent, Grid, Divider, Avatar, Badge, Box } from "@mui/material";
import { Container } from "@mui/system";
import styles from './NotificationList.module.scss';
import NoData from "../../components/noData/noData";
import loader from "../../assets/loader.gif";
import { HTTPMethod, callApi } from "../../services/HttpService/HttpService";
import { NotificationApi } from "../../constants/ApiConstants";
import { handleTimeFormat } from '../../common/Utils/timeFormat/handleTimeFormat';
import PersonIcon from "@mui/icons-material/Person";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons';
import RepeatRoundedIcon from '@mui/icons-material/RepeatRounded';
import NotificationTitle from './NotificationTitle/NotificationTitle';
import { LineOfBusinessEnum } from '../../constants/BusinessConstants';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNotificationCountData } from '../../common/Utils/NotificationCount/useNotificationCount';
import { checkOrderFeedbackExpired } from '../orders-module/OrdersDetails/OrdersDetails';
import { AntSwitch } from '../../components/MuiCustomComponent/MuiCustomComponent';
import { shareFeedbackNotification } from '../../common/enums';
import CustomTablePagination from '../../components/CustomTablePagination/CustomTablePagination';

export const Icons = ({ entityType }) => {
  switch (entityType) {
    case 1:
    case 3:
    case 4:
    case 9:
    case 10:
    case 12:
    case 13:
    case 14:
    case 15:
    case 17:
    case 18:
    case 21:
    case 22:
    case 23:
    case 28:
    case 29:
    case 30:
    case 31:
    case 33:
    case 34:
    case 35:
    case 36:
    case 37:
    case 38:
    case 39:
      return <FontAwesomeIcon icon={faClipboardList} size='lg' className={styles.innerIcon} />
    case 2:
    case 5:
    case 6:
    case 19:
    case 20:
    case 40:
      return <PersonIcon className={styles.innerIcon} />
    case 7:
    case 8:
    case 11:
    case 16:
      return <RepeatRoundedIcon className={styles.innerIcon} />
    case 24:
    case 25:
    case 26:
    case 27:
      return <FontAwesomeIcon icon={faClipboardCheck} className={styles.innerIcon} />
    case 32:
      return <InventoryIcon className={styles.innerIcon} />
    default:
      return <></>;
  }
}

export const handleRedirectTo = (data, e, navigate, navigateToUrl) => {
  const entityType = data.entity_type;
  let url;
  switch (entityType) {
    case 1:
    case 3:
    case 4:
    case 9:
    case 10:
    case 12:
    case 13:
    case 14:
    case 15:
    case 17:
    case 18:
    case 21:
    case 22:
    case 23:
    case 28:
    case 29:
    case 30:
    case 31:
    case 33:
    case 34:
    case 35:
    case 36:
    case 37:
    case 38:
    case 39:
      url = `/orders/order-details/${data.entity_id}`;
      break;
    case 2:
    case 5:
    case 6:
    case 19:
    case 20:
    case 40:
      url = `/${LineOfBusinessEnum.patientsToMembers.toLowerCase()}/${LineOfBusinessEnum.patientToMember.toLowerCase()}-details/${data.entity_id}`;
      break;
    case 7:
    case 8:
    case 11:
    case 16:
      url = `/recurring-orders/recurring-order-details/${data.entity_id}`;
      break;
    case 24:
    case 25:
    case 26:
    case 27:
      url = `/order-approvals/order-approval-details/${data.entity_id}`;
      break;
    case 32:
      url = `/orders/supply-order-details/${data?.entity_id}`;
      break;
    default:
      return;
  }

  const redirectUrl = navigateToUrl || url;
  if (e.ctrlKey) {
    window.open(redirectUrl, '_blank');
  } else {
    navigate(redirectUrl);
  }
};


const NotificationListData = ({showItems, showHeader}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const totalCount = useSelector(state => state.authState.notificationCount?.totalCount);
  const filteredCount = useSelector(state => state.authState.notificationCount?.filteredCount);
  const orderFeedBackExpirationDays = useSelector((state) => state.authState.userData?.data?.application_settings?.order_feed_back_expiration_days)
  const surveyLinkWithAuthForUser = useSelector((state) => state.authState.userData?.data?.application_settings?.survey_link_with_auth_for_user);

  const [notificationListData, setNotificationListData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isShowUnread, setIsShowUnread] = useState(false);

  const [isPaginationLoading, setPaginationLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [nextPagination, setNextPagination] = useState({
    isNextPage: false, 
    paginationIndex: ''
  })
  const [lastPagination, setLastPagination] = useState({
    lastPage: false,
    lastPageCount: 0
  })


  const toggleSetUnRead = (e) => {
    e.stopPropagation();
    setIsShowUnread(e.target.checked);
  };

  const handleReadNotification = async (event, data, navigateToUrl) => {
    event.preventDefault();
    const apiEndpoint = (data?.notification_id) ?
      `${NotificationApi.markAsReadNotification}?NotificationId=${data.notification_id}` :
      `${NotificationApi.markAsReadNotification}?MarkAllAsRead=true`;

    const response = await callApi(HTTPMethod.Get, apiEndpoint);
    if (response && response.status_code == 200 && response.data) {
      fetchNotificationCountData(dispatch)
      if (!data?.notification_id) {
        setNotificationListData(response.data);
        setIsLoading(true);
        await fetchNotificationListData();
      }
      else {
        handleRedirectTo(data, event, navigate, navigateToUrl);
      }
    }
  }

  const handleLastIndexPage = () => {
    if (notificationListData?.older?.length > 0) {
      return notificationListData.older;
    }
    if (notificationListData?.yesterday?.length > 0) {
      return notificationListData.yesterday;
    }
    if (notificationListData?.today?.length > 0) {
      return notificationListData.today;
    } else {
      return [];
    }
  }

  const handlefirstIndexPage = () => {
    if (notificationListData?.today?.length > 0) {
      return notificationListData.today;
    }
    if (notificationListData?.yesterday?.length > 0) {
      return notificationListData.yesterday;
    }
    if (notificationListData?.older?.length > 0) {
      return notificationListData.older;
    } else {
      return [];
    }
  }

  const fetchNotificationListData = async () => {
    setIsLoading(true);

    let apiEndpoint = `${NotificationApi.notificationListing}?OnlyUnReadCount=false&TimeZoneOffset=${new Date().getTimezoneOffset()}`;

    if (isShowUnread) {
      apiEndpoint = apiEndpoint + `&OnlyUnRead=true`;
    }
    if (location.pathname === '/dashboard') {
      apiEndpoint = apiEndpoint + `&Count=3&IsLandingPage=true`;
    } else {
      if (page == 0) {
        apiEndpoint = apiEndpoint + `&Count=${rowsPerPage}&IsNextPage=${true}`;
      } else if (lastPagination.lastPage) {
        apiEndpoint = apiEndpoint + `&Count=${lastPagination.lastPageCount}&IsLastPage=${true}&IsNextPage=${nextPagination.isNextPage}`;
      } else {
        apiEndpoint = apiEndpoint + `&Count=${rowsPerPage}&PaginationIndex=${nextPagination.paginationIndex ? nextPagination.paginationIndex : ''}&IsLastPage=${false}&IsNextPage=${nextPagination.isNextPage}`;
      }
    }

    const response = await callApi(HTTPMethod.Get, apiEndpoint);
    if (response && response.status_code == 200 && response.data) {
      setNotificationListData(response.data);
    }
    setIsLoading(false);
    setPaginationLoading(false);
  };

  useEffect(() => {
    fetchNotificationListData();
  }, [isShowUnread, rowsPerPage, lastPagination.lastPage, page, nextPagination.paginationIndex]);

  const handleChangePage = (_event, newPage, isLastPage = false) => {
    if (page < newPage) {
      const dataArray = handleLastIndexPage();
      setNextPagination({ isNextPage: true, paginationIndex: dataArray[dataArray.length - 1]?.notification_id })
    }
    else {
      const dataArray = handlefirstIndexPage();
      setNextPagination({ isNextPage: false, paginationIndex: dataArray[0]?.notification_id })
    }
    setPage(newPage);
    setLastPagination({ lastPage: false, lastPageCount: 0 })
    if (isLastPage) {
      const count = isShowUnread ? filteredCount : totalCount;
      const lastPageInititalValue = (count - (count % rowsPerPage))
      setLastPagination({ lastPage: true, lastPageCount: count - lastPageInititalValue})
      setNextPagination({ isNextPage: false, paginationIndex: '' })
    }
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPaginationLoading(true);
  }

  const Listing = ({notificationsList}) => {
    return(
      <Card className={location.pathname === '/dashboard' ? styles.noticationsListing :`${styles.noticationsListing} ${styles.notificationListingLanding}`}>
      {
        notificationsList.map((item, index) => (
          <Card key={item.notification_id} variant="outlined" className={styles.notificationListingCard}
          data-testid='notificationCard'
          >
            <CardContent>
              <Grid container>
                <Grid item md={10} sm={10} className={styles.notificationListLayout} onClick={(e) => handleReadNotification(e, item)} data-testid='gridNotification'>
                <Grid item md={1} xs="auto" sm={2} mr={2} className={styles.entityType}>
                  {!item?.is_read ? (<Badge color="error" overlap="circular" badgeContent=" " variant="dot" anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
                    <Avatar children={<Icons entityType={item.entity_type} />} className={styles.icon} />
                  </Badge>)
                    :
                    <Avatar children={<Icons entityType={item.entity_type} />} className={styles.icon} />
                  }
                </Grid>
                <Grid item md={9} sm={8}>
                  <p id={item.notification_id} className={`${styles.title} marginZero`}><b>
                    <NotificationTitle title={item?.title} data={item} readNotification={handleReadNotification}/>
                  </b></p>
                  <p className={`${styles.time} marginZero`}>{handleTimeFormat(item?.created_on_utc)}</p>
                  <p className={styles.message}><NotificationTitle title={item?.body} data={item} readNotification={handleReadNotification}/></p>
                </Grid>
                </Grid>
                {([shareFeedbackNotification.EntityType31, shareFeedbackNotification.EntityType14].includes(item.entity_type)) && checkOrderFeedbackExpired(item?.created_on_utc, orderFeedBackExpirationDays) && surveyLinkWithAuthForUser &&
                  <Grid item md={2} sm={2}>
                    <div className="textAlignEnd fontWeight600">
                      <Link to={`/orders/feedback-order`} state={{orderFeedbackId : item?.entity_id}} className={styles.feedBackLink}>{t("SupportComponent.share_feedback")}</Link>
                    </div>
                  </Grid>
                }
              </Grid>
            </CardContent>
            {notificationsList?.length > 1 &&
              index !==
              notificationsList?.length - 1 && (
                <Divider
                  variant="middle"
                />
              )}
          </Card>))
      }
    </Card>
    )
  }

  return (
    <Box data-testid="NotificationList" className={styles.NotificationList}>
      {showHeader ?
        <>
          <Box className={styles.topHeader}>
            <span className={styles.mainHeadingText}>{t("Dashboard.notifications")}</span>
            <span onClick={(e) => { handleReadNotification(e) }} className={styles.markAllRead} data-testid='markAllRead'> {t("Dashboard.markAllAsRead")} </span>
          </Box>
          <Box display="flex" alignItems="center" mt={2}>
            <AntSwitch
              data-testid="antSwitch"
              checked={isShowUnread}
              onChange={toggleSetUnRead}
              inputProps={{ "aria-label": "only show unread" }}
            />
            <span className={styles.toggleBtnText}>
              {t("Dashboard.onlyShowUnread")}
            </span>
          </Box>
          {
            (notificationListData?.today?.length > 0 || notificationListData?.yesterday?.length > 0 || notificationListData?.older?.length > 0)
              ? (
                <>
                  {notificationListData?.today?.length > 0 && (
                    <Box mt={3}>
                      <span className={styles.subHeading}>{t("Dashboard.today")}</span>
                      <Listing notificationsList={notificationListData?.today} />
                    </Box>
                  )}
                  {notificationListData?.yesterday?.length > 0 && (
                    <Box mt={3}>
                      <span className={styles.subHeading}>{t("Dashboard.yesterday")}</span>
                      <Listing notificationsList={notificationListData?.yesterday} />
                    </Box>
                  )}
                  {notificationListData?.older?.length > 0 && (
                    <Box mt={3}>
                      <span className={styles.subHeading}>{t("Dashboard.older")}</span>
                      <Listing notificationsList={notificationListData?.older} />
                    </Box>
                  )}
                  <CustomTablePagination
                    totalRecord={isShowUnread ? filteredCount : totalCount}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    isLoading={isPaginationLoading}
              />
                </>
              ) : (
                <Box mt={3}><NoData message={isLoading ? <img alt='Loading...' src={loader} /> : t('Dashboard.noNotificationExits')} /></Box>
              )
          }
        </>
        :
        <>
          {notificationListData?.is_landing_page?.length > 0 ?
            <Box className={styles.landingPageBox}><Listing notificationsList={notificationListData?.is_landing_page} /></Box> :
            <NoData message={isLoading ? <img alt='Loading...' src={loader} /> : t('Dashboard.noRecentNotification')} />
          }
        </>
      }
    </Box >
  )
};

const NotificationList = ({ showItems, showHeader = true }) => {
  return (
    location.pathname === '/dashboard' ?
      <NotificationListData showItems={showItems} showHeader={showHeader} /> :
      <Container className="mainContainer">
          <NotificationListData showItems={showItems} showHeader={showHeader} />
      </Container>
  )
}

export default NotificationList;
